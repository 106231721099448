import useLiveCampaignResults from "@/src/common/hooks/useLiveCampaignResults"
import useLiveDominanceResults from "@/src/common/hooks/useLiveDominanceResults"
import { removeUndefined } from "@/src/common/utils"
import PresidentElectionCtx from "@/src/contexts/PresidentElectionCtx"
import {
  ELECTION_API_REDESIGN_DATE,
  TOP_PARTIES_LIVE_RESULTS,
  UPCOMING_ELECTIONS_YEAR
} from "@/src/elections/business-layer/config"
import { GET_RACE_SOURCE } from "@/src/elections/business-layer/constants"
import { extractPartyDominanceTableData } from "@/src/elections/business-layer/selectors"
import {
  BaseElectionTemplateProps,
  MapTemplateProps
} from "@/src/elections/business-layer/types"
import { ElectionSources2023 } from "@/src/elections/data-layer/types"
import { useRouter } from "next/router"
import { useContext } from "react"

const useLiveElectionResults = (
  props: BaseElectionTemplateProps
): BaseElectionTemplateProps => {
  const electionTemplateProps = props
  const { query } = useRouter()
  const year = (query.year as string) ?? "2023"
  const state = query.state as string
  const { race, level, liveMapLevel } = props
  const PresidentElectionContext = useContext(PresidentElectionCtx)
  const presidentRaceUseContext =
    year === UPCOMING_ELECTIONS_YEAR && race === "president" && !state

  const TOP_PARTIES =
    Number(year) <= new Date(ELECTION_API_REDESIGN_DATE).getFullYear() && !state
      ? TOP_PARTIES_LIVE_RESULTS.join(",")
      : undefined

  const { data: liveMapCampaignsData } = useLiveCampaignResults({
    resultProps: removeUndefined({
      race,
      state,
      year: year as string,
      level: liveMapLevel,
      party: TOP_PARTIES,
      source:
        year === UPCOMING_ELECTIONS_YEAR && race === "governor" && state
          ? ElectionSources2023.LGA
          : GET_RACE_SOURCE(year, race, Boolean(state))
    }),
    refreshInterval: props.refreshInterval,
    initialCampaignData: props.liveMapCampaigns
  })

  const { data: campaignData } = useLiveCampaignResults({
    resultProps: removeUndefined({
      race,
      state,
      year: year as string,
      level,
      party: TOP_PARTIES,
      source: presidentRaceUseContext
        ? PresidentElectionContext.source
        : GET_RACE_SOURCE(year, race, Boolean(state))
    }),
    refreshInterval: props.refreshInterval,
    initialCampaignData: props.campaigns
  })

  const { data: partyDominanceData } = useLiveDominanceResults({
    resultProps: removeUndefined({
      race,
      state,
      year: year as string,
      level,
      source: presidentRaceUseContext
        ? PresidentElectionContext.source
        : GET_RACE_SOURCE(year, race, Boolean(state))
    }),
    refreshInterval: props.refreshInterval,
    initialPartyDominanceData: props.partyDominanceData
  })

  return props.refreshInterval
    ? {
        ...electionTemplateProps,
        campaigns: campaignData ?? [],
        liveMapCampaigns: liveMapCampaignsData ?? [],
        mapProps: {
          ...(electionTemplateProps.mapProps as MapTemplateProps)
        },
        partyDominanceData,
        partyDominanceTableProps: {
          partyDominanceHeaders:
            electionTemplateProps!.partyDominanceTableProps!
              .partyDominanceHeaders,
          partyDominance: extractPartyDominanceTableData(
            partyDominanceData,
            state ? "constituency_name" : "state_name",
            {
              generateHref: !state,
              keyField: state ? "constituency_code" : "state"
            }
          )
        }
      }
    : props
}

export default useLiveElectionResults
