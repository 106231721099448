import DesktopSocialShare from "@/src/common/components/SocialShareCard/DesktopSocialShare"
import MobileSocialShare from "@/src/common/components/SocialShareCard/MobileSocialShare"
import { ElectionTime } from "@/src/common/types"
import { URLFactory } from "@/src/elections/business-layer/factories/URLFactory"
import { ElectionsDataTimeline } from "@/src/elections/components/ElectionsDataTimeline"
import { DefaultEventProperties } from "@/src/elections/data-layer/types"
import { useAnalytics } from "@/src/sb/components/Analytics"
import { SEO } from "@/src/sb/components/SEO"
import { SEOProps } from "@/src/sb/components/SEO/settings"
import { V2_FEATURE_TOGGLES } from "@/src/sb/constants/configs"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import { UPCOMING_ELECTIONS_YEAR } from "../../business-layer/config"
import { HISTORICAL_RESULTS } from "../../business-layer/constants"
import useElectionArticles from "../../business-layer/hooks/useElectionArticles"
import {
  Breadcrumb,
  DropdownItem,
  ElectionArticleInfo,
  Race,
  RegionSummary,
  SocialShareEventProps,
  UpcomingElection
} from "../../business-layer/types"
import { ExternalHTTP } from "../../data-layer"
import Layout from "../Layout"
import StatePicker from "../StatePicker"
import TabGroup from "../TabGroup"
import Template from "../Template"

type DefaultElectionLayoutProps = {
  seo: SEOProps
  electionIndex: number
  articles: Array<ElectionArticleInfo>
  elections: Array<UpcomingElection>
  breadcrumbs: Array<Breadcrumb>
  dropdownItems: Array<DropdownItem>
  electionsTimeline: ElectionTime[]
  showSignupCTA: boolean
  children: any
  defaultEventProperties: DefaultEventProperties
  externalHTTP: ExternalHTTP
  activeState?: RegionSummary
}

export const DefaultElectionLayout = ({
  seo,
  elections,
  electionIndex,
  activeState,
  articles: initialArticles,
  breadcrumbs,
  dropdownItems,
  electionsTimeline,
  defaultEventProperties,
  showSignupCTA,
  externalHTTP,
  children
}: DefaultElectionLayoutProps) => {
  const router = useRouter()
  const analytics = useAnalytics()
  const { slug, title } = elections[electionIndex]
  const race = slug as Race
  const { articles } = useElectionArticles(
    externalHTTP,
    race,
    activeState?.code,
    initialArticles
  )
  const [pageTitle, setPageTitle] = useState<string>("")
  const pageURL = URLFactory.absolute(
    race,
    defaultEventProperties.year,
    activeState?.code ?? "",
    defaultEventProperties.constituency
  )

  useEffect(() => setPageTitle(document.title), [])

  const onClickMyElections = (
    source: "my-elections-cta" | "nav-follow-races"
  ) => {
    analytics.clickMyElections({ ...defaultEventProperties, source })
  }

  const onClickGubernatorialLiveBlog = () => {
    analytics.clickMyGubernatorialLiveBlogCard(defaultEventProperties)
  }

  const onClickArticle = ({ title, slug }: ElectionArticleInfo) => {
    analytics.clickArticleInFeed({
      ...defaultEventProperties,
      page: router.pathname,
      title,
      slug
    })
  }

  const onClickStateDropdownItem = (selected: string, selectedCode: string) => {
    analytics.clickStateDropdownItem({
      ...defaultEventProperties,
      selected,
      selectedCode
    })
  }

  const onClickYear = (year: string) => {
    analytics.clickNavigationTimelineYear({
      ...defaultEventProperties,
      year
    })
  }

  const onSocialShare = (media: SocialShareEventProps["type"]) => {
    analytics.clickSocialShareButton({
      ...defaultEventProperties,
      type: media
    })
  }

  useEffect(() => {
    analytics.viewElectionPage(defaultEventProperties)
  }, [analytics])

  if (race === "governor") {
    seo.imageURL = URLFactory.absolute("socialCardGovernorImage")
  }

  return (
    <>
      <MobileSocialShare
        pageTitle={pageTitle}
        pageURL={pageURL}
        buttons={{ linkedin: false, email: false }}
        onClickShare={onSocialShare}
      />

      <SEO {...seo} />
      <Layout onClickFollowRaces={() => onClickMyElections("nav-follow-races")}>
        <Template.Container>
          <Template.Page.TwoColumns
            showSignupCTA={showSignupCTA}
            onClickMyElections={() => onClickMyElections("my-elections-cta")}
            onClickGubernatorialLiveBlog={onClickGubernatorialLiveBlog}
            onClickArticle={onClickArticle}
            articles={articles}
            showGubernatorialLiveBlog={race === "governor"}
          >
            <Template.Card.PreElection>
              <DesktopSocialShare
                pageTitle={pageTitle}
                pageURL={pageURL}
                onClickShare={onSocialShare}
              />

              <StatePicker
                title={activeState ? activeState?.name + " State" : title}
                breadcrumbs={breadcrumbs}
                dropdownItems={dropdownItems}
                onClickItem={onClickStateDropdownItem}
              />

              <TabGroup tabProps={elections} selectedIndex={electionIndex} />
              {V2_FEATURE_TOGGLES.has(HISTORICAL_RESULTS) && (
                <ElectionsDataTimeline
                  onClickYear={onClickYear}
                  race={race}
                  timeline={electionsTimeline}
                  state={router.query.state as string | undefined}
                  year={
                    (router.query.year as string) || UPCOMING_ELECTIONS_YEAR
                  }
                />
              )}
              {children}
            </Template.Card.PreElection>
          </Template.Page.TwoColumns>
        </Template.Container>
      </Layout>
    </>
  )
}
