import CheckMarkFilled from "@/public/assets/img/checkmark--filled.svg"
import { PartyDominanceTableRow } from "@/src/common/types"
import TableV2, {
  TableHeaderV2,
  TablePropsV2
} from "@/src/elections/components/Table/TableV2"
import classNames from "classnames"
import Image from "next/legacy/image"

type PartyDominanceTableProps = Omit<
  TablePropsV2<PartyDominanceTableRow>,
  "headerCell" | "bodyCell"
>

export default function PartyDominanceTable({
  idField,
  headers,
  rows,
  onClickRow
}: PartyDominanceTableProps) {
  const headerCellRender = (
    name: string,
    row: TableHeaderV2<PartyDominanceTableRow>
  ) => {
    if (row.meta?.imageUrl)
      return (
        <span className="flex justify-center items-center w-full">
          <span className="mr-2.5">{name}</span>
          <Image
            layout={"fixed"}
            src={row.meta.imageUrl}
            width={18}
            height={18}
            alt="_"
          />
        </span>
      )
    return null
  }

  const bodyCellRender = (
    value: string | number | undefined,
    key: string | number,
    row: PartyDominanceTableRow
  ) => {
    if (key === row.winner)
      return (
        <span className="block w-full px-3">
          <span className="inline-block relative leading-none">
            {value}
            <span
              className={classNames({
                "absolute -translate-y-1/2 top-1/2 -right-4": !!value
              })}
            >
              <Image
                layout={"fixed"}
                src={CheckMarkFilled}
                width={14}
                height={14}
                alt="_"
              />
            </span>
          </span>
        </span>
      )

    return null
  }

  return (
    <TableV2
      className="mt-3 md:mt-4 max-h-80"
      idField={idField}
      headers={headers}
      rows={rows}
      onClickRow={onClickRow}
      headerCell={headerCellRender}
      bodyCell={bodyCellRender}
    />
  )
}
