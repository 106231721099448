/**
 * This is not really a React hooks
 */
import { MutableRefObject } from "react"

export type scrollToViewProps = {
  ref: MutableRefObject<HTMLElement | null>
  margin?: number
  scrollMargin?: number
}

const scrollInToView = ({
  margin = 8,
  ref,
  scrollMargin
}: scrollToViewProps) => {
  if (!ref.current) {
    return
  }

  ref.current.style.scrollMarginTop = `${margin + (scrollMargin ?? 0)}px`
  ref.current.scrollIntoView()
}

export default scrollInToView
