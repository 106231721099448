import { ExternalHTTP } from "@/src/elections/data-layer"
import type { NextPage } from "next"
import { useSession } from "next-auth/react"
import { useRouter } from "next/router"

import { HIDDEN_ELECTION_RACES } from "../../business-layer/config"
import { RACE_TO_NAME } from "../../business-layer/constants"
import {
  campaignToCandidateInfo,
  sumCandidateCampaignVote
} from "../../business-layer/selectors"
import { BaseElectionTemplateProps, Race } from "../../business-layer/types"
import { ElectionsAPI } from "../../data-layer"
import Template from "../Template"
import Typography from "../Typography"
import { DefaultElectionLayout } from "./DefaultElectionLayout"
import { UpcomingElectionPageContents } from "./UpcomingElectionPageContents"

export type UpcomingElectionTemplateProps = {
  electionsAPI: ElectionsAPI
  externalHTTP: ExternalHTTP
} & BaseElectionTemplateProps

export const UpcomingElectionTemplate: NextPage<
  UpcomingElectionTemplateProps
> = ({
  seo,
  states,
  elections,
  constituencies,
  config,
  campaigns,
  electionIndex,
  articles,
  electionsAPI,
  externalHTTP,
  activeState,
  breadcrumbs,
  dropdownItems,
  electionsTimeline,
  candidateTableProps,
  mapProps
}: UpcomingElectionTemplateProps) => {
  const { status: sessionStatus } = useSession()
  const router = useRouter()
  const { slug } = elections[electionIndex]
  const loggedIn = sessionStatus === "authenticated"
  const race = slug as Race
  const level: "country" | "state" = activeState ? "state" : "country"
  const state = activeState?.name
  const candidates = sumCandidateCampaignVote(
    campaigns.map(campaignToCandidateInfo)
  )
  const constituency = constituencies.find(
    ({ code }) => code === (router.query.constituency as string | undefined)
  )?.name
  const defaultEventProperties = {
    race,
    level,
    state,
    year: String(router.query.year),
    constituency,
    loggedIn
  }
  return (
    <DefaultElectionLayout
      seo={seo}
      elections={elections}
      electionIndex={electionIndex}
      articles={articles}
      activeState={activeState}
      breadcrumbs={breadcrumbs}
      dropdownItems={dropdownItems}
      electionsTimeline={electionsTimeline}
      defaultEventProperties={defaultEventProperties}
      showSignupCTA={false}
      externalHTTP={externalHTTP}
    >
      <Template.Card.ElectionPagePadding>
        {HIDDEN_ELECTION_RACES.includes(race) ? (
          <Typography
            type="p16"
            weight="medium"
            className="mt-10 text-gray-800"
          >
            {`Watch this space, we are releasing ${RACE_TO_NAME[race]} data soon`}
          </Typography>
        ) : (
          <UpcomingElectionPageContents
            states={states}
            config={config}
            activeState={activeState}
            candidates={candidates}
            constituencies={constituencies}
            externalHTTP={externalHTTP}
            electionsAPI={electionsAPI}
            defaultEventProperties={defaultEventProperties}
            election={elections[electionIndex]}
            mapProps={mapProps}
            candidateTableProps={candidateTableProps}
          />
        )}
      </Template.Card.ElectionPagePadding>
    </DefaultElectionLayout>
  )
}
