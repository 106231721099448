import { findUpcomingElection, formatNumber } from "@/src/common/utils"
import { TableHeader } from "@/src/elections/components/Table"
import { useAnalytics } from "@/src/sb/components/Analytics"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { Suspense } from "react"

import { GET_NOW, UPCOMING_ELECTIONS_YEAR } from "../../business-layer/config"
import {
  ELECTION_NO_RESULT_COLOR,
  ELECTION_PARTY_COLOR_OTHERS
} from "../../business-layer/constants"
import { SelectorFactory } from "../../business-layer/factories/SelectorFactory"
import {
  idFromGeoJSONProperties,
  selectMapFeaturesForState,
  titleFromGeoJSONProperties
} from "../../business-layer/selectors"
import {
  BaseElectionEventProperties,
  CandidateCampaignInfo,
  GeoJSONFeatureProperties,
  MapTemplateProps,
  PartyDominanceInfo,
  Race,
  RegionSummary
} from "../../business-layer/types"
import { Constituency } from "../../data-layer/types"
import {
  CANDIDATE_TABLE_HEADERS,
  IncumbentPill,
  MapPopover,
  PARTY_TABLE_HEADERS
} from "../Map/MapPopover"
import Placeholder from "../Map/Placeholder"
import Typography from "../Typography"

type MapFeatureProps = { race: Race } & MapTemplateProps & {
    states: Array<RegionSummary>
    activeState?: RegionSummary
    candidates: Array<CandidateCampaignInfo>
    constituencies: Array<Constituency>
    defaultEventProperties: BaseElectionEventProperties & { loggedIn: boolean }
    idField?: "state" | "lgaCode"
  }

const DynamicMap = dynamic(() => import("../Map"), {
  suspense: false,
  ssr: false
})

export const MapFeature = ({
  mapFeatures,
  activeState,
  candidates,
  states,
  race,
  showTitles,
  avoidCollisions,
  maxCampaignsPerRegion,
  appendStateName,
  stateBoundaries,
  defaultEventProperties,
  constituencies,
  regions,
  legend,
  idField
}: MapFeatureProps) => {
  const analytics = useAnalytics()
  const router = useRouter()
  const filteredMapFeatures = activeState
    ? selectMapFeaturesForState(mapFeatures, activeState.code)
    : mapFeatures
  const campaignSelectorFactory = new SelectorFactory(race)
  const selectRegionColor = campaignSelectorFactory.createCandidateColorPicker(
    candidates,
    idField
      ? idField
      : (race === "president" || race === "governor") && activeState?.code
      ? "lgaCode"
      : "state",
    router.query.year === UPCOMING_ELECTIONS_YEAR
      ? ELECTION_NO_RESULT_COLOR
      : ELECTION_PARTY_COLOR_OTHERS
  )

  const raceUpcomingElectionHasPassed =
    new Date(findUpcomingElection(race)?.date ?? 0) < GET_NOW()

  let tableHeaders:
    | Array<TableHeader<Partial<PartyDominanceInfo>>>
    | Array<TableHeader<Partial<CandidateCampaignInfo>>>
  if (
    race === "governor" &&
    parseInt((router?.query?.year as string) ?? "0") >=
      parseInt(UPCOMING_ELECTIONS_YEAR) &&
    raceUpcomingElectionHasPassed
  ) {
    tableHeaders = PARTY_TABLE_HEADERS.slice(0, PARTY_TABLE_HEADERS.length - 1)

    tableHeaders.push({
      name: "Total votes",
      id: "votes",
      key: ({ votes }) => formatNumber(votes ?? 0),
      keepIfEmpty: true
    })
  }

  if (race === "governor" && !raceUpcomingElectionHasPassed) {
    tableHeaders = CANDIDATE_TABLE_HEADERS.slice(0, 3)

    tableHeaders.push({
      name: "Incumbent",
      id: "incumbent",
      key: IncumbentPill,
      keepIfEmpty: false
    })
  }

  const selectCandidates = campaignSelectorFactory.createCampaignSelector(
    candidates,
    router.query.year as string,
    maxCampaignsPerRegion,
    appendStateName ? states : undefined
  )

  let viewIntentCopy = "candidates"
  if (raceUpcomingElectionHasPassed) viewIntentCopy = "results"

  const mapTitle = activeState
    ? `Select a ${
        race === "president" ? "LGA" : "constituency"
      } to view the ${viewIntentCopy} 👇`
    : `Select a state to view the ${viewIntentCopy} 👇`

  const onClickMapFeature = ({
    constituency,
    state
  }: GeoJSONFeatureProperties) => {
    analytics.clickElectionMap({
      ...defaultEventProperties,
      state: states.find(({ code }) => code === state)?.name,
      constituency: constituencies.find(({ code }) => code === constituency)
        ?.name
    })
  }

  const onClickCandidate = ({ href }: { href?: string }) => {
    analytics.clickElectionCandidate({
      ...defaultEventProperties,
      href
    })
  }

  const onClickViewAllCandidates = (selected: string, selectedCode: string) => {
    analytics.clickViewAllElectionCandidates({
      ...defaultEventProperties,
      selected,
      selectedCode
    })
  }

  return mapFeatures.features.length === 0 ? (
    <Placeholder.NoElections className="mt-10" />
  ) : (
    <>
      <Typography type="p16" weight="medium" className="mt-10 text-gray-800">
        {mapTitle}
      </Typography>
      <p className="mt-1 text-gray-600 text-xs md:hidden">
        Use two-fingers or +, - icon to navigate the map
      </p>

      <Suspense fallback={<Placeholder className="mt-4" />}>
        <DynamicMap
          data={filteredMapFeatures}
          showTitles={showTitles}
          selectRegionColor={selectRegionColor}
          avoidCollisions={avoidCollisions}
          highlightOnHover="opacity"
          stateBoundaries={stateBoundaries}
          legend={legend}
          initialSelectedFeatureId={
            (router.query.constituency || router.query.lga) as string
          }
          className="mt-4"
          renderPopover={({ onClose, properties }) => (
            <MapPopover
              tableHeaders={
                tableHeaders as Array<
                  TableHeader<Partial<CandidateCampaignInfo>>
                >
              }
              onClickClose={onClose}
              onClickItem={onClickCandidate}
              emptyMessage={
                router.query?.year === UPCOMING_ELECTIONS_YEAR &&
                race === "president" &&
                !router.query?.state
                  ? "Coming soon"
                  : undefined
              }
              onClickLink={() =>
                onClickViewAllCandidates(
                  titleFromGeoJSONProperties(properties),
                  idFromGeoJSONProperties(properties)
                )
              }
              {...selectCandidates(properties)}
            />
          )}
          onClickMapFeature={onClickMapFeature}
          interactiveRegions={regions}
        />
      </Suspense>
    </>
  )
}
