import Link, { StearsLinkProps } from "@/src/common/components/Link"
import { Menu } from "@headlessui/react"
import classNames from "classnames"
import { forwardRef } from "react"

import { DropdownItem } from "../../business-layer/types"
import Icon from "../Icon"
import Typography from "../Typography"

export type DropdownProps<T extends DropdownItem> = {
  cta: string
  items: Array<T>
  prefetch?: boolean
  onClickItem?: (option: string, id: string) => void
}

// see: https://headlessui.com/react/menu#integrating-with-next-js
type PolyfillLinkProps = React.PropsWithChildren<StearsLinkProps> & {
  href: string
  className?: string
}
const PolyfillLink = forwardRef<any, PolyfillLinkProps>((props, ref) => {
  const { href, children, ...rest } = props
  return (
    <Link legacyBehavior href={href}>
      <a ref={ref} {...rest}>
        {children}
      </a>
    </Link>
  )
})

const Dropdown = ({
  items,
  cta,
  prefetch = false,
  onClickItem
}: DropdownProps<DropdownItem>) => {
  const chunkSize = 13
  const columns: Array<Array<DropdownItem>> = []
  for (let i = 0; i < items.length; i += chunkSize) {
    columns.push(items.slice(i, i + chunkSize))
  }
  return (
    <Menu>
      {({ open }) => (
        <div className="relative h-full bg-black">
          <Menu.Button className="h-full w-full flex flex-row">
            <Typography
              type="p15x"
              className="text-white w-full h-full pl-4 py-4 pr-1 flex flex-col justify-center"
            >
              {cta}
            </Typography>
            <span className="mr-4 my-auto">
              <Icon.Chevron open={open} size="xs" color="white" />
            </span>
          </Menu.Button>
          <Menu.Items className="absolute right-0 xs!:right-6 w-screen max-w-xs mx-auto mt-4 px-9 py-6 bg-white flex flex-row justify-between shadow-xs md:shadow-sm z-[500]">
            {columns.map((cells, idx) => (
              <div
                key={idx}
                className="flex flex-col justify-start align-middle"
              >
                {cells.map((cell, idx2) => (
                  <Menu.Item key={cell.key}>
                    {({ active: _ }) =>
                      prefetch ? (
                        /* please note: https://headlessui.com/react/menu#integrating-with-next-js */
                        <PolyfillLink
                          href={cell.href}
                          className={classNames([
                            cell.hasElectionActivity
                              ? "text-gray-800 "
                              : "pointer-events-none text-gray-400"
                          ])}
                          onClick={() =>
                            cell?.name && onClickItem?.(cell.name, cell.key)
                          }
                        >
                          <Typography
                            type="p14"
                            className={idx2 === 0 ? "" : "mt-2"}
                          >
                            {cell.name}
                          </Typography>
                        </PolyfillLink>
                      ) : (
                        <a
                          href={cell.href}
                          className={classNames([
                            cell.hasElectionActivity
                              ? "text-gray-800 "
                              : "pointer-events-none text-gray-400"
                          ])}
                          onClick={() =>
                            cell?.name && onClickItem?.(cell.name, cell.key)
                          }
                        >
                          <Typography
                            type="p14"
                            className={idx2 === 0 ? "" : "mt-2"}
                          >
                            {cell.name}
                          </Typography>
                        </a>
                      )
                    }
                  </Menu.Item>
                ))}
              </div>
            ))}
          </Menu.Items>
        </div>
      )}
    </Menu>
  )
}

export default Dropdown
