import { useRouter } from "next/router"
import { useEffect } from "react"

export const useFollowEffect = (
  follow?: (...args: any[]) => void,
  args: any[] = []
) => {
  const router = useRouter()
  useEffect(() => {
    if (follow && router.query.follow === "true") {
      follow(...args)
      router.replace(router.asPath.split("?")[0], undefined, { shallow: true })
    }
  }, [router.isReady, Boolean(follow), ...args])
}
