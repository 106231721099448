import AlertIcon from "@/public/assets/icons/elections/alert.svg"
import scrollInToView from "@/src/common/hooks/scrollInToView"
import PresidentElectionCtx from "@/src/contexts/PresidentElectionCtx"
import {
  RESULT_DISCLAIMER,
  UPCOMING_ELECTIONS_YEAR
} from "@/src/elections/business-layer/config"
import { RACE_ADJECTIVE } from "@/src/elections/business-layer/constants"
import {
  extractPartyDominancePartyData,
  sumCandidateCampaignVote
} from "@/src/elections/business-layer/selectors"
import {
  CandidateCampaignInfo,
  Race,
  RegionSummary
} from "@/src/elections/business-layer/types"
import ResultsCard from "@/src/elections/components/ResultsCard"
import { CandidateCampaignInfoWithVotes } from "@/src/elections/components/ResultsCard/Candidate"
import {
  DefaultEventProperties,
  ElectionSources2023,
  PartyDominance
} from "@/src/elections/data-layer/types"
import { useAnalytics } from "@/src/sb/components/Analytics"
import { useWindowSize } from "@/src/sb/hooks/useWindowSize"
import classNames from "classnames"
import Image from "next/legacy/image"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useRef } from "react"

type PastElectionHeadToHeadTemplateProps = {
  candidates: Array<CandidateCampaignInfo>
  activeState?: RegionSummary
  race: Race
  partyDominanceData: Array<PartyDominance>
  defaultEventProperties: DefaultEventProperties
}

const PastElectionHeadToHeadTemplate = ({
  candidates,
  activeState,
  race,
  partyDominanceData,
  defaultEventProperties
}: PastElectionHeadToHeadTemplateProps) => {
  const { query } = useRouter()
  const [mobile] = useWindowSize()
  const nodeHandle = useRef<HTMLDivElement | null>(null)
  const ElectionContext = useContext(PresidentElectionCtx)

  useEffect(() => {
    if (
      !(
        query?.v === "h2h" &&
        race === "president" &&
        query?.year === UPCOMING_ELECTIONS_YEAR
      )
    )
      return

    scrollInToView({
      ref: nodeHandle,
      scrollMargin: mobile ? 192 : 81
    })
  }, [query?.v])

  const analytics = useAnalytics()

  const headToHeadTitle = `${query.year} ${
    RACE_ADJECTIVE[race] ?? ""
  } results ${activeState ? `- ${activeState?.name} state` : ""}`

  const showDisclaimerExcludeGovernorRace =
    query.year === UPCOMING_ELECTIONS_YEAR &&
    race !== "governor" &&
    race !== "state_house"

  const showDisclaimerOnlyGovernorRace =
    query.year === UPCOMING_ELECTIONS_YEAR && race === "governor"

  const informationCopy = [
    {
      show: (activeState && race === "governor") || race === "president",
      copy: `The winning candidate must secure the majority
            of the vote and at least 25% of the vote in two thirds of the ${
              { president: "states", governor: "LGAs" }[race as string]
            }.`
    },
    {
      show: showDisclaimerExcludeGovernorRace,
      copy: RESULT_DISCLAIMER
    },
    {
      show: showDisclaimerExcludeGovernorRace,
      copy: "Declared results have been announced by INEC in Abuja. Provisional results reflect collation only at the Local Government Area level."
    },
    {
      show: showDisclaimerOnlyGovernorRace,
      copy: "These results are sourced from INEC's state collation centres and media reporting."
    },
    {
      show: showDisclaimerOnlyGovernorRace,
      copy: "Declared results have been announced by INEC at a state collation centre. Provisional results reflect collation only at the Local Government Area level."
    },
    {
      show: race === "state_house",
      copy: "These results are sourced from INEC's state collation centres and media reporting"
    }
  ]

  const onClickCandidate = ({ href }: CandidateCampaignInfoWithVotes) => {
    analytics.clickHeadToHeadCandidate({
      ...defaultEventProperties,
      href
    })
  }

  const isPresidentCountryLevel2023 =
    query.year === UPCOMING_ELECTIONS_YEAR &&
    race === "president" &&
    !activeState?.code

  const headToHeadHeader = (
    <section ref={nodeHandle}>
      <h3 className="font-jakarta md:hidden font-medium text-[15px] leading-[25px]">
        {headToHeadTitle}
      </h3>
      <h3 className="font-jakarta hidden md:block font-medium text-[20px] leading-[32px]">
        {headToHeadTitle}
      </h3>

      {informationCopy.map((information, idx) => {
        if (!information.show) return null

        return (
          <section className="flex items-start mb-1" key={idx}>
            <div className="mr-2 min-w-[14px]">
              <Image src={AlertIcon} alt="info" width={14} height={14} />
            </div>

            <p className="md:hidden mt-1 text-gray-600 text-xs">
              {information.copy}
            </p>
            <p className="-md:hidden mt-1 text-gray-600 text-sm">
              {information.copy}
            </p>
          </section>
        )
      })}

      <section className="text-right mb-2">
        {isPresidentCountryLevel2023 ? (
          <div className="inline-flex ml-auto items-center">
            <span
              className={classNames(
                "ml-3 text-sm font-medium md:font-bold mr-3",
                [
                  ElectionContext.source === ElectionSources2023.TV
                    ? "text-stears_black"
                    : "text-gray-400"
                ]
              )}
            >
              Declared
            </span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                onChange={(e) => {
                  ElectionContext.setSource(
                    e.target.checked
                      ? ElectionSources2023.TRACKA
                      : ElectionSources2023.TV
                  )
                }}
              />
              <div className="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
              <span
                className={classNames("ml-3 text-sm font-medium md:font-bold", [
                  ElectionContext.source === ElectionSources2023.TRACKA
                    ? "text-stears_black"
                    : "text-gray-400"
                ])}
              >
                Provisional
              </span>
            </label>
          </div>
        ) : null}
      </section>
    </section>
  )

  // President
  if (race === "president") {
    const presidentialCandidates = sumCandidateCampaignVote(candidates).sort(
      (prev, next) => (next?.votes ?? 0) - (prev?.votes ?? 0)
    ) as CandidateCampaignInfoWithVotes[]
    return !activeState ? (
      <>
        {headToHeadHeader}

        {presidentialCandidates
          .slice(0, query.year === UPCOMING_ELECTIONS_YEAR ? 4 : 3)
          .map((candidate) => (
            <div
              key={`${candidate.slug}-${candidate.partyCode}`}
              className="mb-4"
            >
              <ResultsCard.Candidate
                {...candidate}
                onClickCandidate={() => onClickCandidate(candidate)}
              />
            </div>
          ))}
      </>
    ) : (
      <>
        {headToHeadHeader}

        <ResultsCard.VotesPerParty
          parties={extractPartyDominancePartyData(partyDominanceData)}
          title={undefined}
        />
      </>
    )
  }

  // Governor
  if (race === "governor") {
    return !activeState ? (
      <>
        {headToHeadHeader}

        <ResultsCard.PartyDominance
          title={"Governors Per Party"}
          parties={extractPartyDominancePartyData(partyDominanceData)}
        />
      </>
    ) : (
      <>
        {headToHeadHeader}
        <ResultsCard.HeadToHead
          hideVotePercentage={true}
          candidates={(
            sumCandidateCampaignVote(
              candidates
            ) as CandidateCampaignInfoWithVotes[]
          ).slice(0, 2)}
          onClickCandidate={onClickCandidate}
        />
      </>
    )
  }

  // Other races: Senate, House of Reps, State house of Assembly
  return (
    <>
      {headToHeadHeader}

      <ResultsCard.PartyDominance
        title={`${race === "senate" ? "Senators" : "Reps"} per party`}
        parties={extractPartyDominancePartyData(partyDominanceData)}
      />
    </>
  )
}

export default PastElectionHeadToHeadTemplate
