import Link from "@/src/common/components/Link"
import { Fragment } from "react"

import { Breadcrumb, BreadcrumbListItem } from "../../business-layer/types"
import Typography from "../Typography"

export type BreadcrumbsProps = {
  items: Array<Breadcrumb>
}

const Breadcrumbs = ({ items }: BreadcrumbsProps) =>
  items.length < 1 ? null : (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: items.reduce(
              (
                items: Array<BreadcrumbListItem>,
                item,
                idx
              ): Array<BreadcrumbListItem> => {
                if (!item.href && items.length > idx + 1) return items

                const _item: BreadcrumbListItem = {
                  "@type": "ListItem",
                  position: idx + 1,
                  name: item.text
                }

                return [
                  ...items,
                  item.href
                    ? {
                        ..._item,
                        item: item.href
                      }
                    : _item
                ]
              },
              []
            )
          })
        }}
      />

      <ol className="flex overflow-x-scroll no-scrollbar align-middle py-1">
        {items.map(({ text, href }, idx) => (
          <Fragment key={idx}>
            {href && idx < items.length - 1 ? (
              <li key={idx}>
                <Link legacyBehavior href={href}>
                  <a>
                    <Typography
                      type="p15x"
                      className="text-blue-600 whitespace-nowrap"
                    >
                      {text}
                    </Typography>
                  </a>
                </Link>
              </li>
            ) : (
              <li key={idx}>
                <Typography
                  type="p15x"
                  className="text-gray-800 whitespace-nowrap"
                >
                  {text}
                </Typography>
              </li>
            )}
            {idx !== items.length - 1 ? (
              <li key={idx + "-separator"}>
                <Typography type="p15x" className="text-gray-300 mx-2">
                  /
                </Typography>
              </li>
            ) : null}
          </Fragment>
        ))}
      </ol>
    </>
  )

Breadcrumbs.Nav = ({
  className: extraClassNames = "",
  ...props
}: BreadcrumbsProps & { className?: string }) => (
  <nav className={`px-6 py-4 md:px-8 bg-white ${extraClassNames}`}>
    <Breadcrumbs {...props} />
  </nav>
)

export default Breadcrumbs
