import { PartyDominanceTableRow } from "@/src/common/types"
import {
  findUpcomingElection,
  formatNumber,
  getPercentage,
  raceIsRepresentative
} from "@/src/common/utils"
import PresidentElectionCtx from "@/src/contexts/PresidentElectionCtx"
import {
  ELECTION_API_REDESIGN_DATE,
  GET_NOW,
  UPCOMING_ELECTIONS_YEAR
} from "@/src/elections/business-layer/config"
import PastElectionHeadToHeadTemplate from "@/src/elections/components/ElectionTemplate/PastElectionHeadToHeadTemplate"
import HistoricalCandidateTable from "@/src/elections/components/HistoricalCandidateTable"
import { TableHeader } from "@/src/elections/components/Table"
import { useAnalytics } from "@/src/sb/components/Analytics"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import React, { Suspense, useContext } from "react"

import {
  DEFAULT_LEGEND,
  ELECTION_NO_RESULT_COLOR,
  ELECTION_PARTY_COLOR_OTHERS,
  GENDER,
  RACE_ADJECTIVE,
  REGION
} from "../../business-layer/constants"
import { SelectorFactory } from "../../business-layer/factories/SelectorFactory"
import {
  capitalize,
  idFromGeoJSONProperties,
  selectLegendFromParties,
  selectMapFeaturesForState,
  titleFromGeoJSONProperties
} from "../../business-layer/selectors"
import {
  CandidateCampaignInfo,
  CandidateTableTemplateProps,
  GeoJSONFeatureProperties,
  MapTemplateProps,
  PageTemplateConfig,
  PartyDominanceInfo,
  PartyDominanceProps,
  Race,
  RegionSummary
} from "../../business-layer/types"
import {
  Constituency,
  DefaultEventProperties,
  ElectionSources2023,
  PartyDominance
} from "../../data-layer/types"
import { MapPopover, PARTY_TABLE_HEADERS } from "../Map/MapPopover"
import Placeholder from "../Map/Placeholder"
import PartyDominanceTable from "../PartyDominanceTable"
import Typography from "../Typography"
import { MapFeature } from "./MapFeature"

type PastElectionPageContentsProps = {
  states: Array<RegionSummary>
  constituencies: Array<Constituency>
  defaultEventProperties: DefaultEventProperties
  partyDominanceTableProps?: PartyDominanceProps
  race: Race
  config: PageTemplateConfig
  partyDominanceData: Array<PartyDominance>
  activeState?: RegionSummary
  candidates?: Array<CandidateCampaignInfo>
  liveMapCandidates?: Array<CandidateCampaignInfo>
  mapProps?: MapTemplateProps
  parties?: Array<PartyDominanceInfo>
  candidateTableProps?: CandidateTableTemplateProps
}

type PartyDominanceMapFeatureProps = MapTemplateProps &
  Pick<
    PastElectionPageContentsProps,
    | "activeState"
    | "defaultEventProperties"
    | "states"
    | "constituencies"
    | "race"
    | "parties"
  >

const hackToGetLGAs = (parties: Array<PartyDominanceInfo>) =>
  parties
    .filter(({ lgaCode }) => lgaCode)
    .reduce(
      (acc, party) => ({
        ...acc,
        [party.lgaCode!]: party.lga
      }),
      {}
    )

export const PastElectionPageContents = ({
  partyDominanceTableProps,
  mapProps,
  activeState,
  candidates,
  liveMapCandidates,
  states,
  race,
  parties,
  config,
  defaultEventProperties,
  constituencies,
  candidateTableProps,
  partyDominanceData
}: PastElectionPageContentsProps) => {
  const ElectionContext = useContext(PresidentElectionCtx)
  const { query } = useRouter()
  const analytics = useAnalytics()
  const raceIsNotRepresentatives = !raceIsRepresentative(race)

  const lgas = raceIsNotRepresentatives && parties ? hackToGetLGAs(parties) : {}

  const onHistoricalCandidateTableRow = ({
    name,
    slug
  }: CandidateCampaignInfo) => {
    analytics.clickVotePerCandidate({
      ...defaultEventProperties,
      name,
      slug
    })
  }

  const onClickRow = ({ name, href }: PartyDominanceTableRow) => {
    analytics.clickElectionDominantPartyCandidate({
      href,
      name
    })
  }

  let legends = DEFAULT_LEGEND

  if (race === "governor") {
    legends = legends.filter((legend) => legend.name !== "Awaiting")
  }

  const tableTitle = `Party popularity in each ${
    activeState ? (raceIsNotRepresentatives ? "LGA" : "constituency") : "state"
  }`

  const tableDescription = `${
    raceIsRepresentative(race) && !activeState
      ? `The table shows the % of ${RACE_ADJECTIVE[race]} votes won in each state.`
      : `The table shows the % of votes won in each ${
          activeState ? "constituency" : "state"
        }.`
  } ${!activeState ? "Click on a state to see detailed results." : ""}`

  const candidateTableRows = candidates || []

  const paddedLiveMapCandidates = liveMapCandidates ?? []
  let liveMapCandidateTotalVoteCount = 0
  let liveMapCandidateYear = "0"

  if (paddedLiveMapCandidates.length) {
    liveMapCandidateTotalVoteCount = paddedLiveMapCandidates[0]?.totalVotes ?? 0
    liveMapCandidateYear = paddedLiveMapCandidates[0]?.year ?? "0"
  }

  const raceIsGovernorCountryLeveLOrRepsWithCandidateNotPastYear =
    race === "governor" &&
    !activeState?.code &&
    paddedLiveMapCandidates.length &&
    !(parseInt(liveMapCandidateYear) < parseInt(UPCOMING_ELECTIONS_YEAR))

  const regionKey = "state"

  // Confirm and add data for summarised Others winning using party dominance data
  if (raceIsGovernorCountryLeveLOrRepsWithCandidateNotPastYear) {
    const candidatesWons = paddedLiveMapCandidates.reduce(
      (summary, candidate): Record<string, boolean[]> => {
        const candidateRegion = candidate[regionKey]
        const candidateWon = candidate?.won ?? false
        if (!candidateRegion) return summary

        if (summary[candidateRegion]) {
          summary[candidateRegion].push(candidateWon)

          return summary
        }

        return { ...summary, [candidateRegion]: [candidateWon] }
      },
      {} as Record<string, boolean[]>
    )

    Object.keys(candidatesWons).forEach((candidateRegion) => {
      if (candidatesWons[candidateRegion].some((won: boolean) => won)) return

      const otherStateWon = partyDominanceData.find(
        (partyDominance) =>
          partyDominance.party === "Others" &&
          partyDominance[regionKey] === candidateRegion &&
          partyDominance.won
      )

      if (!otherStateWon) return

      paddedLiveMapCandidates.push({
        slug: "",
        name: "",
        party: otherStateWon.party,
        race: otherStateWon.race,
        year: String(otherStateWon.year),
        href: "",
        constituency: otherStateWon.constituency_code,
        constituency_code: otherStateWon.constituency_code,
        won: otherStateWon.won,
        state: otherStateWon.state,
        lga: otherStateWon.lga_name,
        lgaCode: otherStateWon.lga_code,
        age: 0,
        gender: "" as keyof typeof GENDER,
        incumbent: false,
        runningMate: "",
        stateName: otherStateWon.state_name,
        photoURL: otherStateWon.candidate_photo_url ?? undefined,
        region: "" as keyof typeof REGION,
        winner: otherStateWon.won,
        partyName: otherStateWon.party,
        partyCode: otherStateWon.party,
        partyLogoURL: otherStateWon.party_logo_url ?? undefined,
        partyColor: ELECTION_PARTY_COLOR_OTHERS,
        image: "",
        votes: otherStateWon.vote_count,
        totalVotes: liveMapCandidateTotalVoteCount,
        votePercentageFormatted: getPercentage(
          otherStateWon.vote_count,
          liveMapCandidateTotalVoteCount,
          true
        ),
        votePercentage: getPercentage(
          otherStateWon.vote_count,
          liveMapCandidateTotalVoteCount
        ),
        stateMinimum: false
      })
    })
  }

  const isRepresentativesPage = !raceIsNotRepresentatives
  const presidentCountryLevel =
    race === "president" &&
    Number(query.year) >= new Date(ELECTION_API_REDESIGN_DATE).getFullYear() &&
    !activeState?.code

  const candidateTableHeader =
    ((race === "governor" || isRepresentativesPage) &&
    parseInt((query?.year as string) ?? "0") >=
      parseInt(UPCOMING_ELECTIONS_YEAR)
      ? candidateTableProps?.candidateTableHeaders?.slice(
          0,
          candidateTableProps?.candidateTableHeaders.length - 1
        )
      : candidateTableProps?.candidateTableHeaders) || []

  return (
    <>
      {config.candidateHeadToHead && candidateTableRows ? (
        <PastElectionHeadToHeadTemplate
          candidates={candidateTableRows}
          partyDominanceData={partyDominanceData}
          activeState={activeState}
          race={race}
          defaultEventProperties={defaultEventProperties}
        />
      ) : null}

      {config.partyDominanceMap && mapProps && parties ? (
        <PartyDominanceMapFeature
          {...mapProps}
          key={mapProps.mapFeatures.features.length}
          race={race}
          parties={parties}
          activeState={activeState}
          states={states}
          defaultEventProperties={defaultEventProperties}
          constituencies={constituencies}
        />
      ) : null}

      {config.liveMap && mapProps && liveMapCandidates ? (
        <MapFeature
          {...mapProps}
          key={mapProps.mapFeatures.features.length}
          idField={
            presidentCountryLevel &&
            ElectionContext.source === ElectionSources2023.TRACKA
              ? "lgaCode"
              : undefined
          }
          legend={legends}
          activeState={activeState}
          candidates={paddedLiveMapCandidates}
          states={states}
          race={race}
          constituencies={constituencies}
          defaultEventProperties={defaultEventProperties}
        />
      ) : null}

      {config.candidateTable &&
      candidateTableProps &&
      parseInt(query.year as string) < parseInt(UPCOMING_ELECTIONS_YEAR) ? (
        !isRepresentativesPage ||
        (query.constituency && isRepresentativesPage) ? (
          <>
            <Typography type="p15" weight="medium" className="md:hidden mt-10">
              Votes for each candidate
            </Typography>
            <Typography type="p20" weight="medium" className="-md:hidden mt-10">
              Votes for each candidate
            </Typography>

            <HistoricalCandidateTable
              idField="slug"
              headers={candidateTableProps.candidateTableHeaders || []}
              rows={candidateTableRows.filter(({ constituency }) =>
                isRepresentativesPage
                  ? constituency === query.constituency
                  : true
              )}
              onClickRow={onHistoricalCandidateTableRow}
            />
          </>
        ) : null
      ) : null}

      {!(
        query.year === UPCOMING_ELECTIONS_YEAR &&
        (race === "president" || (race === "senate" && !activeState?.code))
      ) &&
      config.partyDominanceTable &&
      partyDominanceTableProps ? (
        <>
          <Typography type="p15" weight="medium" className="md:hidden mt-10">
            {tableTitle}
          </Typography>
          <Typography type="p20" weight="medium" className="-md:hidden mt-10">
            {tableTitle}
          </Typography>
          <Typography type="p15" className="md:hidden mt-1 text-gray-600">
            {tableDescription}
          </Typography>
          <Typography type="p18" className="-md:hidden mt-1 text-gray-600">
            {tableDescription}
          </Typography>
          <PartyDominanceTable
            className="mt-3 md:mt-4 max-h-80"
            idField="slug"
            headers={partyDominanceTableProps.partyDominanceHeaders}
            rows={partyDominanceTableProps.partyDominance}
            onClickRow={onClickRow}
          />
        </>
      ) : null}

      {config.candidateTable &&
      candidateTableProps &&
      query.lga &&
      raceIsNotRepresentatives ? (
        <section className="mb-4">
          <Typography
            type="p15"
            weight="medium"
            className="md:hidden mt-10 capitalize"
          >
            {capitalize((lgas as any)[query.lga as string])}
          </Typography>
          <Typography
            type="p20"
            weight="medium"
            className="-md:hidden mt-10 capitalize"
          >
            {capitalize((lgas as any)[query.lga as string])}
          </Typography>

          <HistoricalCandidateTable
            idField="slug"
            headers={candidateTableProps.candidateTableHeaders || []}
            rows={
              liveMapCandidates?.filter(
                ({ lgaCode }) => lgaCode === query.lga
              ) || []
            }
            onClickRow={onHistoricalCandidateTableRow}
          />
        </section>
      ) : null}

      {/* Show candidates for each constituency at the state level for representatives */}
      {config.candidateTable && candidateTableProps
        ? !query.constituency && !query.lga
          ? raceIsNotRepresentatives
            ? Object.entries(lgas).map(([code, name]: any) => (
                <section key={code} className="mb-4">
                  <Typography
                    type="p15"
                    weight="medium"
                    className="md:hidden mt-10 capitalize"
                  >
                    {capitalize(name)}
                  </Typography>
                  <Typography
                    type="p20"
                    weight="medium"
                    className="-md:hidden mt-10 capitalize"
                  >
                    {capitalize(name)}
                  </Typography>

                  <HistoricalCandidateTable
                    idField="slug"
                    headers={candidateTableHeader}
                    rows={
                      paddedLiveMapCandidates?.filter(
                        ({ lgaCode }) => lgaCode === code
                      ) || []
                    }
                    onClickRow={onHistoricalCandidateTableRow}
                  />
                </section>
              ))
            : constituencies.map((pageConstituency) => (
                <section key={pageConstituency.code} className="mb-4">
                  <Typography
                    type="p15"
                    weight="medium"
                    className="md:hidden mt-10"
                  >
                    {pageConstituency.name}
                  </Typography>
                  <Typography
                    type="p20"
                    weight="medium"
                    className="-md:hidden mt-10"
                  >
                    {pageConstituency.name}
                  </Typography>

                  <HistoricalCandidateTable
                    idField="slug"
                    headers={candidateTableHeader}
                    rows={candidateTableRows.filter(
                      ({ constituency }) =>
                        constituency === pageConstituency.code
                    )}
                    onClickRow={onHistoricalCandidateTableRow}
                  />
                </section>
              ))
          : null
        : null}
    </>
  )
}

const DynamicMap = dynamic(() => import("../Map"), {
  suspense: false,
  ssr: false
})

const PartyDominanceMapFeature = ({
  mapFeatures,
  activeState,
  states,
  race,
  showTitles,
  avoidCollisions,
  appendStateName,
  maxCampaignsPerRegion,
  parties,
  hideLosersWhenDataIsIncomplete,
  stateBoundaries,
  defaultEventProperties,
  constituencies,
  regions
}: PartyDominanceMapFeatureProps) => {
  const analytics = useAnalytics()
  const router = useRouter()
  const filteredMapFeatures = activeState
    ? selectMapFeaturesForState(mapFeatures, activeState.code)
    : mapFeatures
  const selectorFactory = new SelectorFactory(race)
  const selectRegionColor = selectorFactory.createDominantPartyColorPicker(
    parties || [],
    race === "president" ? "stateCode" : "constituencyCode",
    router.query.year === UPCOMING_ELECTIONS_YEAR
      ? ELECTION_NO_RESULT_COLOR
      : ELECTION_PARTY_COLOR_OTHERS
  )
  const selectDominantParties = selectorFactory.createDominantPartySelector(
    parties || [],
    router.query.year as string,
    maxCampaignsPerRegion,
    appendStateName ? states : undefined,
    hideLosersWhenDataIsIncomplete
  )

  const raceUpcomingElectionHasPassed =
    new Date(findUpcomingElection(race)?.date ?? 0) < GET_NOW()

  let tableHeaders: Array<TableHeader<Partial<PartyDominanceInfo>>>

  if (
    (race === "governor" || raceIsRepresentative(race)) &&
    parseInt((router?.query?.year as string) ?? "0") >=
      parseInt(UPCOMING_ELECTIONS_YEAR) &&
    raceUpcomingElectionHasPassed
  ) {
    tableHeaders = PARTY_TABLE_HEADERS.slice(0, PARTY_TABLE_HEADERS.length - 1)

    tableHeaders.push({
      name: "Total votes",
      id: "votes",
      key: ({ votes }) => formatNumber(votes ?? 0),
      keepIfEmpty: true
    })
  }

  const mapTitle = activeState
    ? "Select a constituency to view the candidates 👇"
    : "Click on any state to view results 👇"

  const onClickMapFeature = ({
    constituency,
    state
  }: GeoJSONFeatureProperties) => {
    analytics.clickElectionMap({
      ...defaultEventProperties,
      state: states.find(({ code }) => code === state)?.name,
      constituency: constituencies.find(({ code }) => code === constituency)
        ?.name
    })
  }

  const onClickItem = ({
    href,
    party: name
  }: {
    href: string
    party: string
  }) => {
    analytics.clickElectionDominantPartyCandidate({
      ...defaultEventProperties,
      href,
      name
    })
  }

  const onClickLink = (selected: string, selectedCode: string) => {
    analytics.clickViewAllElectionPartiesForState({
      ...defaultEventProperties,
      selected,
      selectedCode
    })
  }

  const othersLegend = { name: "Others", color: ELECTION_PARTY_COLOR_OTHERS }
  const noResultLegend = { name: "Awaiting", color: ELECTION_NO_RESULT_COLOR }
  let legend = selectLegendFromParties(parties || [])
    .filter(({ name }) => name !== "Others")
    .sort()
    .concat(othersLegend)

  if (race !== "governor") {
    legend = legend.concat(noResultLegend)
  }

  return mapFeatures.features.length === 0 ? (
    <Placeholder.NoElections className="mt-10" />
  ) : (
    <>
      <Typography type="p16" weight="medium" className="mt-10 text-gray-800">
        {mapTitle}
      </Typography>
      <Suspense fallback={<Placeholder className="mt-4" />}>
        <DynamicMap
          data={filteredMapFeatures}
          showTitles={showTitles}
          avoidCollisions={avoidCollisions}
          stateBoundaries={stateBoundaries}
          legend={legend}
          highlightOnHover="opacity"
          selectRegionColor={selectRegionColor}
          initialSelectedFeatureId={router.query.constituency as string}
          className="mt-4"
          palette={{}}
          renderPopover={({ onClose, properties }) => (
            <MapPopover
              onClickClose={onClose}
              onClickItem={onClickItem}
              tableHeaders={tableHeaders}
              emptyMessage={`No ${RACE_ADJECTIVE[race]} election happened in this year`}
              onClickLink={() =>
                onClickLink(
                  titleFromGeoJSONProperties(properties),
                  idFromGeoJSONProperties(properties)
                )
              }
              {...selectDominantParties(properties)}
            />
          )}
          onClickMapFeature={onClickMapFeature}
          interactiveRegions={regions}
        />
      </Suspense>
    </>
  )
}
