import useVisibilityChange from "@/src/common/hooks/useVisibilityChange"
import { removeUndefined } from "@/src/common/utils"
import { ELECTION_CAMPAIGN_RESULTS_API_URL } from "@/src/elections/business-layer/config"
import { Race } from "@/src/elections/business-layer/types"
import { ElectionsAPIInstance } from "@/src/elections/data-layer"
import {
  Campaign,
  ElectionSources2023,
  PaginatedResponse
} from "@/src/elections/data-layer/types"
import useSWR from "swr-v1"

type LiveElectionResultsResponse<T> = {
  data: T[]
  loading: boolean
}

type CampaignItems = Partial<Omit<PaginatedResponse<Campaign>, "items">> & {
  items: Campaign[]
}

type useLiveCampaignResultsParams = {
  resultProps: {
    year: string
    race: Race
    state?: string
    lga?: string
    level?: string
    party?: string
    source?: ElectionSources2023
  }
  refreshInterval: number
  initialCampaignData?: Campaign[]
}

const useLiveCampaignResults = ({
  resultProps,
  refreshInterval,
  initialCampaignData
}: useLiveCampaignResultsParams): LiveElectionResultsResponse<Campaign> => {
  const { tabIsVisible } = useVisibilityChange()
  const enableLiveResult = Boolean(refreshInterval)
  const commonSWRConfig = {
    refreshInterval: tabIsVisible ? refreshInterval * 1000 : 0
  }
  // fetch campaign lga results for only president race at country and state level
  const { data, error } = useSWR<CampaignItems>(
    enableLiveResult
      ? [ELECTION_CAMPAIGN_RESULTS_API_URL, removeUndefined(resultProps)]
      : null,
    ElectionsAPIInstance.fetchAllCampaignResultsSWRFetcher.bind(
      ElectionsAPIInstance
    ),
    {
      ...commonSWRConfig,
      fallbackData: { items: initialCampaignData ?? [] }
    }
  )

  return {
    data: data?.items || [],
    loading: !data && !error
  }
}

export default useLiveCampaignResults
