import useLiveElectionResults from "@/src/common/hooks/useLiveElectionResults"

import { BaseElectionTemplateProps } from "../../business-layer/types"
import { ElectionsAPI, ExternalHTTP } from "../../data-layer"
import { PastElectionTemplate } from "./PastElectionTemplate"
import { UpcomingElectionTemplate } from "./UpcomingElectionTemplate"

export type ElectionTemplateProps = {
  electionsAPI: ElectionsAPI
  externalHTTP: ExternalHTTP
  templateProps: BaseElectionTemplateProps
}

const ElectionTemplate = ({
  templateProps,
  electionsAPI,
  externalHTTP
}: ElectionTemplateProps) => {
  const liveElectionProps = useLiveElectionResults(templateProps)

  return templateProps.config.template === "past" ? (
    <PastElectionTemplate
      {...liveElectionProps}
      electionsAPI={electionsAPI}
      externalHTTP={externalHTTP}
    />
  ) : (
    <UpcomingElectionTemplate
      {...liveElectionProps}
      electionsAPI={electionsAPI}
      externalHTTP={externalHTTP}
    />
  )
}

export default ElectionTemplate
