import { Breadcrumb, DropdownItem } from "../../business-layer/types"
import Breadcrumbs from "../Breadcrumbs"
import Dropdown from "../Dropdown"
import Typography from "../Typography"

type StatePickerProps = {
  title: string
  breadcrumbs: Array<Breadcrumb>
  dropdownItems: Array<DropdownItem>
  onClickItem?: (option: string, id: string) => void
}

const StatePicker = ({
  title,
  breadcrumbs,
  dropdownItems,
  onClickItem
}: StatePickerProps) => {
  return (
    <>
      <div className="px-8 flex flex-row jusify-between -md:hidden">
        <h1 className="w-full h-12 font-bold font-jakarta text-[28px] leading-[40px]">
          {title}
        </h1>
        {dropdownItems.length === 0 ? null : (
          <div className="w-48 h-12 align-bottom">
            <Dropdown
              cta="Select State"
              items={dropdownItems}
              onClickItem={onClickItem}
            />
          </div>
        )}
      </div>
      <Breadcrumbs.Nav
        className="md:py-0 -md:sticky -md:top-[80px] z-[1020]"
        items={breadcrumbs}
      />
      <div className="bg-black flex flex-row md:hidden sticky top-[135px] z-[1020]">
        <Typography
          type="p16"
          className="px-6 py-4 w-full text-white whitespace-nowrap text-ellipsis overflow-clip"
        >
          {title}
        </Typography>
        {dropdownItems.length === 0 ? null : (
          <div className="w-64 min-w-fit border-l border-gray-20">
            <Dropdown
              cta="Select State"
              items={dropdownItems}
              onClickItem={onClickItem}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default StatePicker
