import { ElectionSources2023 } from "@/src/elections/data-layer/types"
import { createContext } from "react"

const PresidentElectionCtx = createContext<
  { source: ElectionSources2023 } & Record<string, any>
>({
  source: ElectionSources2023.IREV
})

export default PresidentElectionCtx
