import useTryLocation from "@/src/common/hooks/useTryLocation"
import { findUpcomingElection } from "@/src/common/utils"
import { GET_NOW } from "@/src/elections/business-layer/config"
import { ELECTION_NO_RESULT_COLOR } from "@/src/elections/business-layer/constants"
import { useAnalytics } from "@/src/sb/components/Analytics"
import { MOBILE_APP_SCHEME_MY_ELECTIONS_PREFERENCE } from "@/src/sb/constants/configs"
import { useSession } from "next-auth/react"
import { useRouter } from "next/router"

import { URLFactory } from "../../business-layer/factories/URLFactory"
import useElectionPreferences from "../../business-layer/hooks/useElectionPreferences"
import { useFollowEffect } from "../../business-layer/hooks/useFollowEffect"
import {
  isFollowing,
  noCandidatesMessage
} from "../../business-layer/selectors"
import {
  BaseElectionEventProperties,
  CandidateCampaignInfo,
  CandidateTableTemplateProps,
  MapTemplateProps,
  PageTemplateConfig,
  PartyDominanceInfo,
  Race,
  RegionSummary,
  UpcomingElection
} from "../../business-layer/types"
import { ElectionsAPI, ExternalHTTP } from "../../data-layer"
import { Constituency } from "../../data-layer/types"
import { CANDIDATE_TABLE_HEADERS, IncumbentPill } from "../Map/MapPopover"
import Table, { TableHeader } from "../Table"
import Typography from "../Typography"
import UpcomingElectionInfo from "../UpcomingElectionInfo"
import { MapFeature } from "./MapFeature"

export enum PageFeature {
  Map = "map",
  CandidatesTable = "candidates-table",
  PartyDominanceTable = "party-dominance-table"
}

const DEFAULT_LEGEND = [
  { name: "Upcoming", color: "#90EE90" },
  { name: "No elections", color: ELECTION_NO_RESULT_COLOR }
]

export type UpcomingElectionMapPageContentsProps = {
  states: Array<RegionSummary>
  candidates: Array<CandidateCampaignInfo>
  constituencies: Array<Constituency>
  electionsAPI: ElectionsAPI
  externalHTTP: ExternalHTTP
  election: UpcomingElection
  defaultEventProperties: BaseElectionEventProperties & { loggedIn: boolean }
  config: PageTemplateConfig
  activeState?: RegionSummary
  mapProps?: MapTemplateProps
  candidateTableProps?: CandidateTableTemplateProps
}

type CandidatesTableFeatureProps = {
  race: Race
} & CandidateTableTemplateProps &
  Pick<
    UpcomingElectionMapPageContentsProps,
    "candidates" | "activeState" | "defaultEventProperties"
  >

export const UpcomingElectionPageContents = ({
  states,
  candidates,
  constituencies,
  config,
  electionsAPI,
  externalHTTP,
  activeState,
  defaultEventProperties,
  election,
  mapProps,
  candidateTableProps
}: UpcomingElectionMapPageContentsProps) => {
  const { status: sessionStatus } = useSession()
  const analytics = useAnalytics()
  const {
    preferences,
    mutatePreferences,
    loading: loadingPreferences
  } = useElectionPreferences(externalHTTP, electionsAPI)
  const { slug, date, notice } = election
  const race = slug as Race
  const loading = sessionStatus === "loading" || loadingPreferences || false
  const loggedIn = sessionStatus === "authenticated"
  const following = isFollowing(constituencies, preferences)
  const onClickSelectElections = useTryLocation({
    location: MOBILE_APP_SCHEME_MY_ELECTIONS_PREFERENCE,
    fallback: URLFactory.myElections
  })

  let onClickAction: (active?: boolean) => void
  let followOrUnfollow: ((active?: boolean) => void) | undefined

  if (!loggedIn) {
    onClickAction = (active = !following) => {
      analytics.clickFollowElection({
        ...defaultEventProperties,
        action: active ? "follow" : "unfollow"
      })

      onClickSelectElections()
    }
  } else {
    followOrUnfollow = (active = !following) => {
      analytics.clickFollowElection({
        ...defaultEventProperties,
        action: active ? "follow" : "unfollow"
      })
      mutatePreferences(
        constituencies.map(({ code }) => ({
          election_id: code,
          active
        }))
      )
    }

    onClickAction = () => {
      followOrUnfollow?.()
      onClickSelectElections()
    }
  }

  useFollowEffect(followOrUnfollow, [true])

  const noCandidates = candidates.length === 0
  return noCandidates ? (
    <Typography type="p16" weight="medium" className="mt-10 text-gray-800">
      {noCandidatesMessage(race, activeState?.code, activeState?.name)}
    </Typography>
  ) : (
    <>
      {!(new Date(date) < new Date()) ? (
        <UpcomingElectionInfo
          notice={notice}
          date={date}
          loading={loading}
          following={following}
          onClickAction={() => onClickAction()}
        />
      ) : null}
      {config.upcomingMap && mapProps ? (
        <MapFeature
          {...mapProps}
          activeState={activeState}
          candidates={candidates}
          idField={race === "governor" ? ("__" as "state") : undefined}
          states={states}
          legend={DEFAULT_LEGEND}
          race={race}
          constituencies={constituencies}
          defaultEventProperties={defaultEventProperties}
        />
      ) : null}
      {config.candidateTable && candidateTableProps ? (
        <CandidatesTableFeature
          {...candidateTableProps}
          candidates={candidates}
          defaultEventProperties={defaultEventProperties}
          activeState={activeState}
          race={race}
        />
      ) : null}
    </>
  )
}

const CandidatesTableFeature = ({
  candidates,
  defaultEventProperties,
  activeState,
  race
}: CandidatesTableFeatureProps) => {
  const router = useRouter()
  const analytics = useAnalytics()
  const onClickRow = ({ href }: CandidateCampaignInfo) => {
    analytics.clickElectionCandidate({
      ...defaultEventProperties,
      href
    })
  }
  const tableTitle =
    race === "president"
      ? `${router.query.year} Presidential Candidates`
      : [
          router.query.year,
          activeState ? activeState.name + " State" : "",
          "Governorship Candidates"
        ].join(" ")
  const tableDescription = "Select a candidate to view their bio"

  const raceUpcomingElectionHasPassed =
    new Date(findUpcomingElection(race)?.date ?? 0) < GET_NOW()

  let tableHeaders:
    | Array<TableHeader<Partial<PartyDominanceInfo>>>
    | Array<TableHeader<Partial<CandidateCampaignInfo>>> =
    CANDIDATE_TABLE_HEADERS

  if (race === "governor" && !raceUpcomingElectionHasPassed) {
    tableHeaders = CANDIDATE_TABLE_HEADERS.slice(0, 3)

    tableHeaders.push({
      name: "Incumbent",
      id: "incumbent",
      key: IncumbentPill,
      keepIfEmpty: false
    })
  }

  return (
    <>
      <Typography type="p15" weight="medium" className="md:hidden mt-10">
        {tableTitle}
      </Typography>
      <Typography type="p20" weight="medium" className="-md:hidden mt-10">
        {tableTitle}
      </Typography>
      <Typography type="p15" className="md:hidden mt-1 text-gray-600">
        {tableDescription}
      </Typography>
      <Typography type="p18" className="-md:hidden mt-1 text-gray-600">
        {tableDescription}
      </Typography>
      <Table
        className="mt-3 md:mt-4 max-h-80"
        idField="slug"
        headers={tableHeaders}
        rows={candidates}
        onClickRow={onClickRow}
      />
    </>
  )
}
