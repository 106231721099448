import { formatNumber, getPercentage } from "@/src/common/utils"
import { CandidateCampaignInfo } from "@/src/elections/business-layer/types"
import TableV2, { TablePropsV2 } from "@/src/elections/components/Table/TableV2"
import tinycolor from "tinycolor2"

type HistoricalCandidateTableProps = Omit<
  TablePropsV2<CandidateCampaignInfo>,
  "headerCell" | "bodyCell"
>

export default function HistoricalCandidateTable({
  idField,
  headers,
  rows,
  onClickRow
}: HistoricalCandidateTableProps) {
  const headerCellRender = (
    value: CandidateCampaignInfo[keyof CandidateCampaignInfo]
  ) => <span className="-ml-2">{value}</span>

  const bodyCellRender = (
    value: CandidateCampaignInfo[keyof CandidateCampaignInfo],
    key: string | number,
    row: CandidateCampaignInfo
  ) => {
    const hasVote = row.votes && row.totalVotes

    switch (key) {
      case "name": // return colored medium weight candidate name
        return (
          <span
            className="font-medium mr-2 -ml-2"
            style={{ color: row.partyColor }}
          >
            {value}
          </span>
        )
        break

      case "partyCode": // return colored party name
        return <span style={{ color: row.partyColor }}>{value}</span>
        break

      case "votesMargin": // return party colored progress bar
        return hasVote ? (
          <span
            className="block w-full h-2 mx-2"
            style={{
              backgroundColor: tinycolor(row.partyColor)
                .setAlpha(0.2)
                .toRgbString()
            }}
          >
            <span
              className="block h-full"
              style={{
                backgroundColor: row.partyColor,
                width: hasVote
                  ? parseInt(getPercentage(row.votes!, row.totalVotes!))
                  : 0
              }}
            ></span>
          </span>
        ) : null
        break

      case "votes": // return formatted value
        return (
          <span className="mx-2 text-sm text-gray-600">
            {value ? formatNumber(value as number) : ""}
          </span>
        )
        break

      default:
        return null
    }
  }

  return (
    <TableV2
      className="mt-2 max-h-80 border-0"
      idField={idField}
      headers={headers}
      rows={rows}
      onClickRow={onClickRow}
      headerCell={headerCellRender}
      bodyCell={bodyCellRender}
      thClass="bg-white"
    />
  )
}
