import { ExternalHTTP } from "@/src/elections/data-layer"
import type { NextPage } from "next"
import { useSession } from "next-auth/react"
import { useRouter } from "next/router"

import { HIDDEN_ELECTION_RACES } from "../../business-layer/config"
import { RACE_TO_NAME } from "../../business-layer/constants"
import { campaignToCandidateInfo } from "../../business-layer/selectors"
import {
  BaseElectionTemplateProps,
  CandidateCampaignInfo,
  Race
} from "../../business-layer/types"
import { ElectionsAPI } from "../../data-layer"
import Template from "../Template"
import Typography from "../Typography"
import { DefaultElectionLayout } from "./DefaultElectionLayout"
import { PastElectionPageContents } from "./PastElectionPageContents"

type PastElectionTemplateProps = {
  electionsAPI: ElectionsAPI
  externalHTTP: ExternalHTTP
} & BaseElectionTemplateProps

export const PastElectionTemplate: NextPage<PastElectionTemplateProps> = ({
  seo,
  elections,
  constituencies,
  electionIndex,
  articles,
  config,
  externalHTTP,
  parties,
  activeState,
  breadcrumbs,
  dropdownItems,
  electionsTimeline,
  partyDominanceTableProps,
  states,
  campaigns,
  liveMapCampaigns,
  mapProps,
  candidateTableProps,
  partyDominanceData
}: PastElectionTemplateProps) => {
  const { status: sessionStatus } = useSession()
  const router = useRouter()
  const { slug } = elections[electionIndex]
  const loggedIn = sessionStatus === "authenticated"
  const race = slug as Race
  const level: "country" | "state" = activeState ? "state" : "country"
  const state = activeState?.name
  const candidates = campaigns
    .map(campaignToCandidateInfo)
    .sort(
      (prev: CandidateCampaignInfo, next: CandidateCampaignInfo) =>
        (next.votes ?? 0) - (prev.votes ?? 0)
    )
  const liveMapCandidates = liveMapCampaigns
    .map(campaignToCandidateInfo)
    .sort((prev, next) => (next?.votes ?? 0) - (prev?.votes ?? 0))
  const constituency = constituencies.find(
    ({ code }) => code === (router.query.constituency as string | undefined)
  )?.name
  const defaultEventProperties = {
    race,
    level,
    state,
    year: String(router.query.year),
    constituency,
    loggedIn
  }
  return (
    <DefaultElectionLayout
      seo={seo}
      elections={elections}
      electionIndex={electionIndex}
      articles={articles}
      activeState={activeState}
      breadcrumbs={breadcrumbs}
      dropdownItems={dropdownItems}
      electionsTimeline={electionsTimeline}
      defaultEventProperties={defaultEventProperties}
      showSignupCTA={false}
      externalHTTP={externalHTTP}
    >
      <Template.Card.ElectionPagePadding>
        {HIDDEN_ELECTION_RACES.includes(race) ? (
          <Typography
            type="p16"
            weight="medium"
            className="mt-10 text-gray-800"
          >
            {`Watch this space, we are releasing ${RACE_TO_NAME[race]} data soon`}
          </Typography>
        ) : (
          <PastElectionPageContents
            activeState={activeState}
            parties={parties}
            states={states}
            candidates={candidates}
            liveMapCandidates={liveMapCandidates}
            config={config}
            race={race}
            constituencies={constituencies}
            defaultEventProperties={defaultEventProperties}
            partyDominanceTableProps={partyDominanceTableProps}
            mapProps={mapProps}
            candidateTableProps={candidateTableProps}
            partyDominanceData={partyDominanceData}
          />
        )}
      </Template.Card.ElectionPagePadding>
    </DefaultElectionLayout>
  )
}
