import useVisibilityChange from "@/src/common/hooks/useVisibilityChange"
import { raceIsRepresentative, removeUndefined } from "@/src/common/utils"
import {
  ELECTION_PARTY_DOMINANCE_CONSTITUENCY_API_URL,
  ELECTION_PARTY_DOMINANCE_LGA_API_URL,
  ELECTION_PARTY_DOMINANCE_STATE_API_URL
} from "@/src/elections/business-layer/config"
import { Race } from "@/src/elections/business-layer/types"
import {
  ElectionSources2023,
  PartyDominance
} from "@/src/elections/data-layer/types"
import { fetcher } from "@/src/sb/core/api/genericFetchers"
import useSWR from "swr-v1"

type LiveElectionResultsResponse<T> = {
  data: T[]
  loading: boolean
}

type useLiveDominanceResultsParams = {
  resultProps: {
    year: string
    race: Race
    state?: string
    lga?: string
    level?: string
    source?: ElectionSources2023
  }
  refreshInterval: number
  initialPartyDominanceData?: PartyDominance[]
}

const useLiveDominanceResults = ({
  resultProps,
  refreshInterval,
  initialPartyDominanceData
}: useLiveDominanceResultsParams): LiveElectionResultsResponse<PartyDominance> => {
  const { tabIsVisible } = useVisibilityChange()

  const enableLiveResult = Boolean(refreshInterval)
  const commonSWRConfig = {
    refreshInterval: tabIsVisible ? refreshInterval * 1000 : 0
  }

  let partyDominanceUrl = ELECTION_PARTY_DOMINANCE_STATE_API_URL

  if (raceIsRepresentative(resultProps.race)) {
    partyDominanceUrl = ELECTION_PARTY_DOMINANCE_CONSTITUENCY_API_URL
  }

  if (resultProps.level === "lga") {
    partyDominanceUrl = ELECTION_PARTY_DOMINANCE_LGA_API_URL
  }

  const { data, error } = useSWR<PartyDominance[]>(
    enableLiveResult ? [partyDominanceUrl, removeUndefined(resultProps)] : null,
    fetcher,
    {
      ...commonSWRConfig,
      fallbackData: initialPartyDominanceData ?? []
    }
  )

  return {
    data: data || [],
    loading: !data && !error
  }
}

export default useLiveDominanceResults
